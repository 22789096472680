<template>
    <q-layout>
        <!-- TOP / BRAND -->
        <!-- navbar -->
        <navbar></navbar>

        <!-- SECTION PREVIEWER -->
        <!----------------------->
        <!-- PAGE SUB HEADER -->
        <div class="bg-mineralgreen">
            <div class="container mx-auto">
                <div class="p-5 row">
                    <div class="col-12 flex flex-col items-start">
                        <h1 class="text-white text-xl font-black font-epigrafica mt-2 uppercase">Nos Products</h1>
                    </div>
                </div>
            </div>
        </div>

        <!-- PRODUCTS -->
        <div class="white">
            <div class="container mx-auto">
                <div class="py-14 bg-cover row justify-center items-center">
                    <div class="col-12 col-sm-12 col-md-4">
                        <q-img width="100%" src="@/assets/media/ibc-international-bio-cosmetics-products-skin-care-cream-en.png" no-spinner />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6">
                        <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">A FAMILY OF PRODUCTS <br> TAILORED TO YOUR NEEDS</h1>
                        <p class="text-2xl text-mineralgreen font-normal font-epigrafica mt-4">Find the perfect combination.</p>
                        <p class="text-2xl text-mineralgreen font-normal font-epigrafica">Find the perfect epidermal balance for your skin.</p>
                    </div>
                </div>
            </div>
        </div>

        <hr>

        <div class="white">
            <div class="container mx-auto">
                <div class="py-14 bg-cover row justify-center">
                    <div class="container mx-auto py-16">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 p-5">
                                <q-img class="img-smooth-zoom" width="100%" src="@/assets/media/ibc-international-bio-cosmetics-product-face.png" no-spinner />
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 p-5">
                                <p class="font-epigrafica text-mineralgreen-dark text-2xl text-justify font-bold mt-5">Face product</p>
                                <p class="font-epigrafica text-mineralgreen-dark text-xl text-justify font-bold">Moisturizer</p>
                                <p class="text-mineralgreen text-base text-justify">
                                    Even if black skin is more resistant to the sun's aggressions, it is still sensitive. The temperate climate of Western countries irritates and dries them out. They lose their radiance. A good moisturizing is therefore essential to recover a supple and soft skin. However, not all moisturizers are created  equal. They must be adapted to each skin type. That's why IBC is working hard to offer moisturizing creams that meet the specific needs of oily, dry or combination skin.
                                </p>
                                <br>
                                <p class="font-epigrafica text-mineralgreen-dark text-xl text-justify font-bold">Anti-imperfection</p>
                                <p class="text-mineralgreen text-base text-justify">
                                    The skin of the face is often subject to pigmentation spots that can have various origins (acne, sun exposure, allergies ...). We specifically study facials to treat these imperfections effectively, but also to unify the complexion.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="container mx-auto py-16">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-8 p-5">
                                <p class="font-epigrafica text-mineralgreen-dark text-2xl text-justify font-bold">Body product</p>
                                <p class="font-epigrafica text-mineralgreen-dark text-xl text-justify font-bold">Moisturizer</p>
                                <p class="text-mineralgreen text-base text-justify">
                                    Inadequately moisturized is dull and uncomfortable. It is tight, certain sensitive areas become rough or peel... Fortunately, a good moisturizing body care product, such as those from our laboratories, adapted to your skin type, is often enough to repair it.
                                </p>
                                <br>
                                <p class="font-epigrafica text-mineralgreen-dark text-xl text-justify font-bold">Lightening - Unifying</p>
                                <p class="text-mineralgreen text-base text-justify">
                                    Skin care that restores comfort and suppleness is what is needed for skin that receives unifying and lightening care. IBC has made it its mission to prepare lightening creams and milks based on extracts of natural products, without hydroquinone or corticoids, in order to preserve the skin of those women who trust us.
                                </p>
                                <br>
                                <p class="font-epigrafica text-mineralgreen-dark text-xl text-justify font-bold">Shower gel</p>
                                <p class="text-mineralgreen text-base text-justify">
                                    The shower gel has long since dethroned soap and is now the most popular product for daily body hygiene. Thanks to its surface-active agents, our shower gel not only removes impurities from the skin, but also pampers it. Beyond its lavender properties, this product has multiple qualities. Thus, we associate it today with the notions of care and pleasure.
                                </p>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 p-5">
                                <q-img class="img-smooth-zoom" ratio="1" src="@/assets/media/ibc-international-bio-cosmetics-product-skin.png" no-spinner />
                            </div>
                        </div>
                    </div>

                    <div class="container mx-auto py-16">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-4 p-5">
                                <q-img class="img-smooth-zoom" width="100%" src="@/assets/media/ibc-international-bio-cosmetics-product-hair-1.png" no-spinner />
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 p-5">
                                <p class="font-epigrafica text-mineralgreen-dark text-2xl text-justify font-bold mt-5">Hair Product</p>
                                <p class="font-epigrafica text-mineralgreen-dark text-xl text-justify font-bold">Shampoo, detangler, relaxer and pomade</p>
                                <p class="text-mineralgreen text-base text-justify">
                                    The hair also needs to be pampered as it deserves, and not just any old way! Synonymous with seduction, it must be cared for with special products to limit its dryness and breakage. IBC's Multi-Nourishing Shampoo and Styling Repair Cream, are the allies of hair beauty to discipline and nourish the hair fiber.
                                </p>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 p-5">
                                <q-img width="100%" src="@/assets/media/ibc-international-bio-cosmetics-product-hair-2-en.png" no-spinner />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- FOOTER CONTENT -->
        <!-- footer -->
        <footerbar></footerbar>
        
    </q-layout>
</template>

<script>
import navbar from "@/views/shared/en/Navbar"
import footerbar from "@/views/shared/en/Footer"
import { useMeta } from "quasar"

export default {
    name: "Products",
    components: { navbar, footerbar },
    data() {
        return {}
    },
    created () {
        useMeta({
            title: 'International Bio Cosmetics | Nos Produits', // sets document title
        })
    }
};
</script>

<style lang="scss">
</style>